import { provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions,
  withIncrementalHydration,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { BrowserCookiesModule } from '@ngx-utils/cookies/browser';
import { provideCustomImageLoader } from '@rma/generic/util/custom-image-loader';
import { errorDecoratorInterceptor } from '@rma/generic/util/http';
import { provideRmaBootstrap } from '@rma/site/public/orc-layout';
import { abortErrorInterceptor } from './interceptors/abort.interceptor';
import { routes } from './routing/app.routes';
import { routerConfig, scrollConfig } from './routing/routing.config';

export const config: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserCookiesModule.forRoot(), BrowserModule),
    provideZoneChangeDetection({ eventCoalescing: true, runCoalescing: true }),
    provideAnimations(),
    //We need DI here because some of our inteceptors are not functions
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([abortErrorInterceptor, errorDecoratorInterceptor]), withFetch()),
    provideRmaBootstrap(),
    provideRouter(routes, withEnabledBlockingInitialNavigation(), withRouterConfig(routerConfig), withInMemoryScrolling(scrollConfig)),
    provideClientHydration(
      withHttpTransferCacheOptions({ includePostRequests: true, includeRequestsWithAuthHeaders: true }),
      withEventReplay(),
      withIncrementalHydration(),
    ),
    provideCustomImageLoader(),
  ],
};
