import { Routes, UrlMatcher, UrlSegment } from '@angular/router';
import { EntityProfileTypes } from '@rma/generic/domain/types';
import { betaRoutes } from './beta.routes';

const agentReviewMatcher: UrlMatcher = (url) => {
  if (url.length < 3 || !Object.values(EntityProfileTypes).find((t) => t === url[0].path) || url[2].path !== 'reviews') {
    return null;
  }
  return {
    consumed: url.slice(0, 3),
    posParams: {
      'entity-profile-type': new UrlSegment(url[0].path, {}),
      'profile-name': new UrlSegment(url[1].path, {}),
    },
  };
};

//TODO : Combine this
const agencyReviewMatcher: UrlMatcher = (url) => {
  if (url.length !== 5 || url[0]?.path !== 'real-estate-agency' || url[3]?.path !== 'reviews') {
    return null;
  }

  return {
    consumed: url.slice(0, 4),
    posParams: {
      'entity-profile-type': new UrlSegment(url[0].path, {}),
      'profile-name': new UrlSegment(url[1].path, {}),
      vertical: new UrlSegment(url[2].path, {}),
    },
  };
};

export const routes: Routes = [
  ...betaRoutes,
  {
    path: '',
    loadComponent: () => import('@rma/site/home'),
    data: {
      showMarketingNav: true,
    },
  },
  {
    path: 'mls/beachesagents',
    loadComponent: () => import('@rma/site/agent-landing'),
  },
  {
    path: 'mls/licensing',
    loadComponent: () => import('@rma/site/licensing'),
  },
  {
    path: 'agent',
    loadComponent: () => import('@rma/site/agent-landing'),
  },
  {
    path: 'sitemap',
    loadChildren: () => import('@rma/site/sitemap'),
    data: {
      title: 'Sitemap',
    },
  },
  {
    path: 'search',
    loadComponent: () => import('@rma/search/nlp-search'),
    data: {
      title: 'Search',
    },
  },
  {
    path: 'blog',
    loadChildren: () => import('@rma/site/blog'),
    data: {
      title: 'Blog',
    },
  },
  {
    path: 'property-report',
    redirectTo: 'property-reports',
  },
  {
    path: 'property-reports',
    loadChildren: () => import('@rma/reports/address-report-view'),
    data: {
      hideFooter: true,
    },
  },
  {
    path: 'reports',
    loadChildren: () => import('@rma/reports/digital-property-report'),
    data: {
      hideFooter: true,
    },
  },
  {
    path: 'property',
    loadChildren: () => import('@rma/addresses/address-profile'),
  },

  //claim Profile routes
  {
    path: 'real-estate-agency/:profile-name/claim-profile',
    loadChildren: () => import('@rma/profiles/claim-individual'),
    data: {
      hideFooter: true,
    },
  },

  // new Profile routes
  {
    path: 'real-estate-agent/:profile-name/sales',
    loadChildren: () => import('@rma/profiles/agent').then((m) => m.agentProfileRoutes),
  },
  {
    path: 'real-estate-agency/:profile-name/sales',
    loadChildren: () => import('@rma/profiles/agency-sales').then((m) => m.agencyProfileRoutes),
  },
  {
    path: 'real-estate-agency/:profile-name/leasing',
    loadChildren: () => import('@rma/profiles/agency-leasing').then((m) => m.agencyProfileRoutes),
  },
  {
    path: 'mortgage-broker/:profile-name/finance',
    loadChildren: () => import('@rma/profiles/mortgage-broker').then((m) => m.mortgageBrokerProfileRoutes),
  },
  // eo new profile routes
  {
    path: 'real-estate-agency/:profile-name/leasing/property-listings',
    loadChildren: () => import('@rma/campaigns/profile'),
    data: {
      isLeasing: true,
    },
  },
  {
    path: 'real-estate-agency/:profile-name/property-listings',
    loadChildren: () => import('@rma/campaigns/profile'),
  },
  {
    matcher: agencyReviewMatcher,
    loadChildren: () => import('@rma/reviews/review').then((m) => m.reviewRoutes),
  },
  {
    matcher: agentReviewMatcher,
    loadChildren: () => import('@rma/reviews/review').then((m) => m.reviewRoutes),
  },
  {
    path: 'real-estate-agent/:profile-name/claim-profile',
    loadChildren: () => import('@rma/profiles/claim-individual'),
    data: {
      hideFooter: true,
    },
  },
  {
    path: 'features',
    loadChildren: () => import('@rma/site/product-features'),
  },
  {
    path: 'search-profile',
    loadComponent: () => import('@rma/profiles/search-profiles').then((m) => m.SearchProfileComponent),
    data: {
      hideFooter: true,
      basicHeader: true,
      fullWidthHeader: true,
    },
    children: [
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
  {
    path: 'create-profile',
    loadComponent: () => import('@rma/profiles/search-profiles').then((m) => m.CreateProfileComponent),
    data: {
      hideFooter: true,
      basicHeader: true,
    },
  },
  {
    path: 'contactform/teamsandoffices',
    loadChildren: () => import('@rma/profiles/team-office-contact'),
  },
  {
    path: 'asset-templates',
    loadChildren: () => import('@rma/site/asset-templates'),
    data: {
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: 'market-report',
    loadChildren: () => import('@rma/reports/market-report'),
    data: {
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: 'real-estate-profile/sales',
    loadChildren: () => import('@rma/locations/sales'),
  },
  {
    path: 'real-estate-profile/leasing',
    loadChildren: () => import('@rma/locations/leasing'),
  },
  {
    path: 'finance',
    loadChildren: () => import('@rma/locations/finance'),
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('@rma/subscriptions/pricing'),
  },
  //Temporary while we migrate review form
  {
    path: 'write-review/:verticalType/:referralId',
    loadChildren: () => import('@rma/reviews/review-flow'),
  },
  {
    path: 'share-review/:convertReviewCode',
    loadChildren: () => import('@rma/reviews/convert-review-flow'),
  },
  {
    path: 'share-review/:verticalType/referral/:referralCode',
    loadChildren: () => import('@rma/reviews/convert-review-flow'),
  },
  {
    path: 'share-review/:convertReviewCode/opt-out',
    loadChildren: () => import('@rma/reviews/convert-review-flow'),
  },
  {
    path: 'share-review/:verticalType/referral/:referralCode/opt-out',
    loadChildren: () => import('@rma/reviews/convert-review-flow'),
  },
  {
    path: 'share-review/:verticalType/:convertReviewCode',
    loadChildren: () => import('@rma/reviews/convert-review-flow'),
  },
  {
    path: 'share-review/:verticalType/:convertReviewCode/opt-out',
    loadChildren: () => import('@rma/reviews/convert-review-flow'),
  },
  //Temporary while we migrate review form
  {
    path: 'write-review/:verticalType/:referralId',
    loadChildren: () => import('@rma/reviews/review-form').then((m) => m.tempReviewsRoute),
  },
  {
    path: 'write-review',
    loadChildren: () => import('@rma/reviews/review-form').then((m) => m.reviewFormRoutes),
  },
  {
    path: 'tracked-properties',
    loadChildren: () => import('@rma/campaigns/track-campaign'),
  },
  {
    path: '',
    loadChildren: () => import('@rma/accounts/activation'),
  },
  {
    path: '',
    loadChildren: () => import('@rma/accounts/member-invitation'),
  },
  {
    path: '',
    loadChildren: () => import('@rma/accounts/login'),
  },
  {
    path: 'unsubscribe/:emailId',
    loadComponent: () => import('@rma/accounts/unsubscribe'),
  },
  {
    path: '',
    loadChildren: () => import('@rma/site/static-documents'),
  },
  // Error Pages
  {
    path: '',
    loadChildren: () => import('@rma/generic/fallback-pages'),
  },
];
