import { ApplicationConfig, enableProdMode, importProvidersFrom, mergeApplicationConfig } from '@angular/core';
import { HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { provideDeviceIdentification } from '@rma/generic/util/device-identification';
import { Environment, IS_PRODUCTION, provideRmaEnvironment } from '@rma/generic/util/environment';
import { installTrackJS } from '@rma/generic/util/tracking/track-js';
import { RmaLayoutComponent } from '@rma/site/public/orc-layout';
import 'hammerjs';
import { RecaptchaComponent } from 'ng-recaptcha';
import { config } from './app/app.config';
import { environment as devEnvironment } from './environment/environment';

declare let environment: Environment;

const env = IS_PRODUCTION ? environment : devEnvironment;

if (IS_PRODUCTION) {
  enableProdMode();
  installTrackJS('public-ui');
}

const browserConfig: ApplicationConfig = {
  providers: [
    // We can't add this in the app because it breaks ssr
    importProvidersFrom(HammerModule),
    // this overrides the one that is injected from ssr, so we need to make sure it is done only in the browser version
    provideRmaEnvironment(env),
    // This is cookie based, so it can't be loaded on the server
    provideDeviceIdentification(),
  ],
};

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() =>
    bootstrapApplication(RmaLayoutComponent, mergeApplicationConfig(config, browserConfig)).catch((err) => console.error(err)),
  );
});

RecaptchaComponent.prototype.ngOnDestroy = function () {
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
};

//trigger
